import { Link, NavLink, Outlet, useNavigation } from "react-router-dom";
import "./auth.scss";
import { useRef, useState } from "react";
import { TbDeviceGamepad2 } from "react-icons/tb";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { AiOutlineMenuFold } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import { MdOutlineMessage } from "react-icons/md";
import { MdBookmarkBorder } from "react-icons/md";
import { IoFileTrayFullOutline } from "react-icons/io5";
import { IoStatsChartOutline } from "react-icons/io5";
import { FaSignOutAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../store/reducers/AuthReducer";
import { FaUserCog } from "react-icons/fa";
import { VscUngroupByRefType } from "react-icons/vsc";
import { VscOrganization } from "react-icons/vsc";

const AuthRoot = () => {
	const ref = useRef();
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const [open, setOpen] = useState(false);

	return (
		<div id="body-pd" className={`body_class   ${open ? "body-pd" : ""}`}>
			<header
				className={`header shadow-lg   ${open ? "body-pd" : ""}`}
				id="header">
				<div
					className="header_toggle"
					ref={ref}
					id="header-toggle"
					onClick={() => {
						setOpen(!open);
					}}>
					{!open ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
				</div>
				<div style={{ paddingRight: "2rem" }}>
					<div className="header_img"></div>
				</div>
				{navigation.state !== "idle" && (
					<div
						className="loader-line position-absolute start-0 bottom-0 "
						style={
							{
								// position: "sticky",
							}
						}></div>
				)}
			</header>

			<div className={`l-navbar ${open ? "show" : ""}`} id="nav-bar">
				<nav className="nav">
					<div>
						<Link to={""} className="nav_logo">
							<span className="nav_logo-name">
								<TbDeviceGamepad2 size={25} color="#ffffff" />
								{"  "}Gamification
							</span>
							<div className="line" />
						</Link>
						<div className="nav_list">
							<NavLink
								to={""}
								className={({ isActive, isPending }) =>
									isActive ? "nav_link active" : "nav_link"
								}>
								<FaUserCog />
								<span className="nav_name">User</span>{" "}
							</NavLink>
							<NavLink
								to={"entity"}
								className={({ isActive, isPending }) =>
									isActive ? "nav_link active" : "nav_link"
								}>
								{" "}
								<VscUngroupByRefType />
								<span className="nav_name">Entity type</span>
							</NavLink>
							<NavLink
								to={"organization"}
								className={({ isActive, isPending }) =>
									isActive ? "nav_link active" : "nav_link"
								}>
								<VscOrganization />
								<span className="nav_name">Organization</span>
							</NavLink>
							{/* <NavLink
								to={"bookmark"}
								className={({ isActive, isPending }) =>
									isActive ? "nav_link active" : "nav_link"
								}>
								{" "}
								<MdBookmarkBorder />
								<span className="nav_name">Bookmark</span>
							</NavLink>{" "}
							<NavLink
								to={"files"}
								className={({ isActive, isPending }) =>
									isActive ? "nav_link active" : "nav_link"
								}>
								<IoFileTrayFullOutline />
								<span className="nav_name">Files</span>{" "}
							</NavLink>
							<NavLink
								to={"stats"}
								className={({ isActive, isPending }) => {
									return isActive ? "nav_link active" : "nav_link";
								}}>
								{" "}
								<IoStatsChartOutline />
								<span className="nav_name">Stats</span>{" "}
							</NavLink> */}
						</div>
					</div>
					<div
						onClick={() => {
							dispatch(resetAuth());
						}}
						className="nav_link">
						{" "}
						<FaSignOutAlt />
					</div>
				</nav>
			</div>
			<div className="height-100">
				<Outlet />
			</div>
		</div>
	);
};

export default AuthRoot;
