import React from "react";
import { useSelector } from "react-redux";
import AlertService from "../../helpers/alertService";

function User(props) {
	const { user } = useSelector(state => state.auth);

	const copyToClipBoard = async copyMe => {
		try {
			await navigator.clipboard.writeText(copyMe);
			AlertService.alert("success", "Public key copied to clipboard ");
		} catch (err) {
			AlertService.alert("warning", "Sorry, something went wrong!");
		}
	};

	return (
		<div className="">
			<div className="row">
				<div className="col-lg-12 mb-4 mb-sm-5">
					<div className="card card_user card-style1 border-0">
						<div className="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
							<div className="row align-items-center">
								{/* <div className="col-lg-6 mb-4 mb-lg-0">
										<img
											src="https://bootdey.com/img/Content/avatar/avatar7.png"
											alt="..."
										/>
									</div> */}
								<div className="col-lg-12 px-xl-10">
									<div className="bg-secondary d-lg-inline-block py-1-9 px-1-9 px-sm-6 mb-1-9 rounded w-100">
										<h3 className="h2 text-white mb-0">{user?.companyName}</h3>
									</div>
									<ul className="list-unstyled mb-1-9">
										<li className="mb-2 mb-xl-3 display-28">
											<span className="display-26 text-secondary me-2 font-weight-600 text-nowrap">
												Email:
											</span>{" "}
											{user?.email}
										</li>
										<li className="mb-2 mb-xl-3 display-28">
											<span className="display-26 text-secondary me-2 font-weight-600 text-nowrap">
												Full Name:
											</span>{" "}
											{user?.fullName}
										</li>
										<li className="mb-2 mb-xl-3 display-28">
											<span className="display-26 text-secondary me-2 font-weight-600 text-nowrap">
												Public Key:
											</span>
											<span
												title="Copy"
												className="publicKey"
												onClick={() => {
													copyToClipBoard(user?.publicKey);
												}}>
												{user?.publicKey}
											</span>
										</li>
									</ul>
									<ul className="social-icon-style1 list-unstyled mb-0 ps-0">
										<li>
											<a href="#!">
												<i className="ti-twitter-alt"></i>
											</a>
										</li>
										<li>
											<a href="#!">
												<i className="ti-facebook"></i>
											</a>
										</li>
										<li>
											<a href="#!">
												<i className="ti-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="#!">
												<i className="ti-instagram"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default User;
