import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { Link, useLoaderData, useLocation, useParams } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { RiExternalLinkLine } from "react-icons/ri";

function AddButton({
	pageTitle = "",
	isShowBackButton = false,
	buttonTitle = "",
	action,
	to,
	backTo = "",
	isCategory = false,
}) {
	const loaderData = useLoaderData();
	const { guid } = useParams();
	const { pathname } = useLocation();
	const data = useLoaderData();
	const isDfaultResources = useMemo(() => {
		if (data && !data.data && pathname.includes("resources")) {
			return true;
		} else {
			return false;
		}
	}, [data, pathname]);
	return (
		<React.Fragment>
			<div
				className={`d-flex justify-content-${
					pageTitle ? "between" : "end"
				} align-items-center gap-2 flex-wrap`}>
				{pageTitle && (
					<div className="d-flex align-items-center gap-2">
						{isShowBackButton && (
							<>
								<Link
									className="d-flex align-items-center gap-1"
									to={`/${backTo}/${guid}`}>
									<IoArrowBackCircleOutline size={18} />
									Back
								</Link>
								|
							</>
						)}
						<h4 className="mb-0">{pageTitle}</h4>
					</div>
				)}
				<div className="d-flex gap-2 flex-wrap">
					{isCategory && (
						<Button variant="outline-primary">
							<Link
								className="d-flex align-items-center gap-2"
								to={`/category/${guid}`}>
								{/* <MdOutlineAddCircleOutline size={21} /> */}
								All Categories
								<RiExternalLinkLine />
							</Link>
						</Button>
					)}
					{!pathname.includes("shop") && buttonTitle && (
						<Button
							variant="primary"
							onClick={() => {
								if (action) {
									action();
								}
							}}>
							{!action ? (
								<Link
									className="d-flex align-items-center gap-2"
									state={{ isDefault: isDfaultResources }}
									to={to ? to : "#"}>
									<MdOutlineAddCircleOutline size={21} />
									Add {buttonTitle}
								</Link>
							) : (
								<span className="d-flex align-items-center gap-2">
									<MdOutlineAddCircleOutline size={21} />
									Add {buttonTitle}
								</span>
							)}
						</Button>
					)}
					{loaderData && !!loaderData.length && pathname.includes("shop") && (
						<Button
							variant="primary"
							onClick={() => {
								if (action) {
									action();
								}
							}}>
							{!action ? (
								<Link
									className="d-flex align-items-center gap-2"
									state={{ isDefault: isDfaultResources }}
									to={to ? to : "#"}>
									<MdOutlineAddCircleOutline size={21} />
									Add {buttonTitle}
								</Link>
							) : (
								<span className="d-flex align-items-center gap-2">
									<MdOutlineAddCircleOutline size={21} />
									Add {buttonTitle}
								</span>
							)}
						</Button>
					)}
				</div>
			</div>
			<hr />
		</React.Fragment>
	);
}

export default AddButton;
