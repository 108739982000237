import React, { useContext, useEffect } from "react";
import { Spinner, Table } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Link, useFormAction, useLoaderData } from "react-router-dom";
import { useAppSubmit } from "../../helpers/hooks";
import AlertService from "../../helpers/alertService";
import { TimerContext } from "../../App";
import { LoadingContext } from "../Root/Root";

const generateTable = (items, responsive, show, ids = [], load) => {
	if (Array.isArray(items) && !items?.length) {
		return null;
	}
	return (
		<Table
			bordered
			responsive
			style={{
				backgroundColor: "#fff",
			}}
			className={`text-center mt-3 ${responsive ? "col-11" : ""}`}>
			<thead>
				<tr>
					<th style={{ width: "10px" }}>#</th>
					<th className="align-middle">Name</th>
					<th className="align-middle">Description</th>
					<th className="align-middle">Count</th>
					<th style={{ width: 100 }} className="align-middle">
						Action
					</th>
				</tr>
			</thead>
			<tbody>
				{items?.map((item, index) => {
					return (
						<React.Fragment key={item.id}>
							<tr>
								<td className="align-middle">#{index + 1}</td>
								<td className="align-middle">{item.name}</td>
								<td className="align-middle">{item.description}</td>
								<td className="align-middle">{item.shopItems.length}</td>
								<td className="d-flex gap-1 justify-content-center button_container">
									<div
										className="button_svg sucsses_boredr"
										style={{
											borderColor: "#198754",
										}}>
										<Link to={`form/${item.id}`}>
											<AiOutlineEdit size={21} color="#198754" />
										</Link>
									</div>
									<div
										className="button_svg error_boredr"
										onClick={() => {
											if (ids.find(el => el === item.id && load)) return;
											show(item.id);
										}}>
										{ids.find(el => el === item.id) && load ? (
											<Spinner
												animation="border"
												role="status"
												size="sm"
												style={{ color: "#dc3545" }}
											/>
										) : (
											<AiOutlineDelete size={21} color="#dc3545" />
										)}
									</div>
								</td>
							</tr>
							{item.categories && item.categories.length ? (
								<td colSpan={4} className="align-middle">
									<div className="ms-auto p-0 my-1" style={{ width: "95%" }}>
										{generateTable(item.categories, true, show)}
									</div>
								</td>
							) : null}
						</React.Fragment>
					);
				})}
			</tbody>
		</Table>
	);
};

function RecursiveTable() {
	const items = useLoaderData();
	const submit = useAppSubmit();
	const action = useFormAction();
	const { setLoad, ids, load } = useContext(LoadingContext);

	const onSubmit = async id => {
		setLoad([...ids, id]);
		const formData = new FormData();
		formData.append("id", id);
		submit(formData, { method: "POST", action });
	};
	const { setIsUpdateTimer } = useContext(TimerContext);

	useEffect(() => {
		if (items) {
			setIsUpdateTimer(true);
		}
	}, [items, setIsUpdateTimer]);
	if (!Array.isArray(items)) {
		return false;
	}
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				onSubmit(id);
			})
			.catch(err => {});
	return generateTable(items, false, show, ids, load);
}

export default RecursiveTable;
