import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { Button, Modal, Table, Form, Spinner } from "react-bootstrap";
import ApiService from "../../../helpers/api";
import { useLoaderData, useParams } from "react-router-dom";
import UplodImg from "../../Inputs/UplodImg";
import CustomsInput from "../../Inputs/CustomsInput";
import AlertService from "../../../helpers/alertService";
import { yupResolver } from "@hookform/resolvers/yup";
import { fragmentValidation } from "../../../helpers/validation";
import { TimerContext } from "../../../App";
import { LoadingContext } from "../../Root/Root";

const FragmentsForm = ({ data, id = "", fragmentsData = [], setData }) => {
	const { fragmentImg } = useLoaderData();
	const { itemId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const { setLoad, ids, setLoading, loading } = useContext(LoadingContext);

	const [modalData, setModalData] = useState({});
	const { setIsUpdateTimer } = useContext(TimerContext);
	const [defaultValues, setDefaultValues] = useState({
		name: "",
		image: "",
		imageName: "",
	});
	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(fragmentValidation),
	});

	const { handleSubmit, setValue, reset } = formMethods;
	// useEffect(() => {
	// 	Object.entries(defaultValues).forEach(([key, value]) => {
	// 		setValue(key, value);
	// 	});
	// }, [defaultValues, setValue]);
	const onSubmit = async _data => {
		setIsLoading(true);
		const formData = new FormData();
		Object.entries(_data).forEach(([key, value]) => {
			formData.append(key, value);
		});
		if (!_data?.resourceId) formData.append("resourceId", itemId);
		if (isUpdate) {
			await ApiService.update_resource_fragment(formData)
				.then(res => {
					if (res.data && res.status === 200) {
						AlertService.alert("success", "Data seved");
						const newData = data?.fragments.map(el => {
							if (el.id === res.data.id) {
								return res.data;
							}
							return el;
						});
						setData(prev => {
							return {
								...data,
								fragments: newData,
							};
						});
					}
					setIsUpdateTimer(true);
					onClose();
				})
				.catch(() => {})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			await ApiService.create_resource_fragment(formData)
				.then(res => {
					if (res.status === 200 && res.data) {
						AlertService.alert("success", "Data seved");
						const newData = [...data.fragments, res.data];
						setData(prev => {
							return {
								...data,
								fragments: newData,
							};
						});
					}
					setIsUpdateTimer(true);
					onClose();
				})
				.catch(() => {})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};
	const onClose = () => {
		reset({ name: "", image: "", imageName: "" });
		setOpen(!open);
		setIsUpdate(false);
		setModalData({});
		setDefaultValues({
			name: "",
			image: "",
			imageName: "",
		});
	};
	const show = id =>
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				setLoad([...ids, id]);
				setLoading(true);
				ApiService.delete_resource_fragment(id)
					.then(res => {
						if (res?.status === 200) {
							AlertService.alert("success", "Data seved");
							setData(prev => {
								const newData = data?.fragments?.filter(el => el.id !== id);
								return {
									...prev,
									fragments: newData,
								};
							});
							setIsUpdateTimer(true);
						} else {
							AlertService.alert("error", "Sorry, something went wrong!");
						}
					})
					.catch(error => {
						AlertService.alert("error", "Sorry, something went wrong!");
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch();
	if (itemId && data?.isFragmental) {
		return (
			<>
				<hr />
				<Modal
					show={open}
					onHide={onClose}
					backdrop="static"
					size="lg"
					centered
					keyboard={false}>
					<FormProvider {...formMethods}>
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Modal.Header closeButton>
								<Modal.Title>{!isUpdate ? "Create" : "Update"}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<CustomsInput
									regName={"name"}
									required={true}
									value={defaultValues.name}
									label={"Fragment name"}
									placeholder={"Name"}
								/>

								<UplodImg
									isFullScreen={true}
									options={fragmentImg}
									required={true}
									id={"image_fragment"}
									className="mb-3"
									value={modalData?.imagePath}
									action={setValue}
									regName={"image"}
									labelValue="Image"
									regName2="imageName"
									isModal={false}
									isShowBootons={true}
								/>
							</Modal.Body>
							<Modal.Footer>
								<Button
									disabled={isLoading}
									variant="secondary"
									onClick={onClose}>
									Close
								</Button>
								<Button disabled={isLoading} type="submit" variant="primary">
									{isLoading ? (
										<span className="d-flex align-items-center gap-2">
											<span>Loading...</span>
											<Spinner animation="border" role="status" size="sm" />
										</span>
									) : (
										"Save"
									)}
								</Button>
							</Modal.Footer>
						</Form>
					</FormProvider>
				</Modal>
				{itemId && (
					<>
						<div className="d-flex justify-content-between align-items-center p-2">
							<h5>Fragments</h5>
							<Button
								variant="light"
								onClick={() => {
									setOpen(true);
								}}>
								<MdOutlineAddCircleOutline size={21} />
							</Button>
						</div>
						<div>
							<Table
								bordered
								responsive
								style={{
									backgroundColor: "#fff",
								}}
								className={`text-center mt-3`}>
								<thead>
									<tr>
										<th style={{ width: "10px" }}>#</th>
										<th className="align-middle">Name</th>
										<th className="align-middle">Image</th>
										<th style={{ width: 100 }} className="align-middle">
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{data?.fragments?.map((item, index) => {
										return (
											<React.Fragment key={item.id}>
												<tr>
													<td className="align-middle">{index + 1}</td>
													<td className="align-middle">{item.name}</td>
													<td className="align-middle">
														<img
															src={item.imagePath}
															alt="/"
															height="60"
															className="rounded-1"
														/>
													</td>
													<td className="d-flex gap-1 w-full justify-content-around align-items-center button_container">
														<div
															className="button_svg sucsses_boredr"
															style={{ borderColor: "#198754" }}
															onClick={() => {
																setDefaultValues({
																	name: item.name,
																	image: "",
																	imageName: item.imageName,
																	id: item.id,
																});
																Object.keys(defaultValues).forEach(key => {
																	setValue(key, item[key]);
																});
																setValue("id", item.id);
																setModalData(item);
																setIsUpdate(true);
																setOpen(true);
															}}>
															<AiOutlineEdit size={21} color="#198754" />
														</div>
														<div
															className="button_svg error_boredr"
															onClick={() => {
																if (ids.find(el => el === item.id && loading))
																	return;
																show(item.id);
															}}>
															{ids.find(el => el === item.id) && loading ? (
																<Spinner
																	animation="border"
																	role="status"
																	size="sm"
																	style={{ color: "#dc3545" }}
																/>
															) : (
																<AiOutlineDelete size={21} color="#dc3545" />
															)}
														</div>
													</td>
												</tr>
											</React.Fragment>
										);
									})}
								</tbody>
							</Table>
						</div>
					</>
				)}
			</>
		);
	}
	return null;
};

export default FragmentsForm;
