import React from "react";
import { Card } from "react-bootstrap";
import AddButton from "../butons/AddButton";

function FormLayout({ pageTitle = "", children, isShow = true, backTo }) {
	return (
		<div>
			<AddButton
				pageTitle={pageTitle}
				isShowBackButton={isShow}
				backTo={backTo}
			/>
			<Card className="mt-4 p-3">
				<div className="d-flex flex-column">{children}</div>
			</Card>
		</div>
	);
}

export default FormLayout;
