import { boolean, mixed, number, object, string } from "yup";

const allowedTypes = ["image/jpeg", "image/png"];

export const validetionFunctions = {
	// Checks that either images or imageNames has a length greater than 0.
	imageNames: mixed().test(
		"is-valid-image-name",
		"Invalid file",
		function (value) {
			// 			- File is required. Please upload at least one file.
			// - Invalid file. Please choose a valid file.
			const { images } = this.parent;
			if (!images?.length && !value?.length) {
				return this.createError({
					path: "imageNames",
					message: "Please upload at least one file",
				});
			}
			return true;
		},
	),
	// 	Validates each element in the images array:
	// Checks file type (JPEG or PNG).
	// Checks file size (less than 1 MB).
	images: mixed().test("is-valid-file", "Invalid file", function (value) {
		if (value) {
			value.forEach(element => {
				if (!allowedTypes.includes(element.type)) {
					return this.createError({
						path: "images",
						message: "Only JPEG and PNG file types are allowed.",
					});
				}
				const maxSizeKB = 5120; // 5MB
				if (element.size / 1024 > maxSizeKB) {
					return this.createError({
						path: "images",
						message: `File size must be less than 5 MB.`,
					});
				}
			});
		}

		const { imageNames } = this.parent;
		if (!imageNames?.length && !value?.length) {
			return this.createError({
				path: "images",
				message: "Please upload at least one file",
			});
		}
		if (!value) {
			return false;
		}
		if (Array.isArray(value) && !value.length) {
			return this.createError({
				path: "images",
				message: "Please upload at least one file",
			});
		}
		return true;
	}),
	// 	Validates the rate field:
	// Allows empty values if isFragmental is true.
	// Checks if the value is a valid number using a regular expression.
	rate: string().test("number-rate", "Rate is required", function (value) {
		const { isFragmental } = this.parent;
		if (isFragmental) {
			return true;
		}
		if (!value) {
			return this.createError({
				path: "rate",
				message: "Rate is required. Please provide a numerical value.",
			});
		}
		let reg = new RegExp(/^[+\-]?\d*\.?\d+(?:[Ee][+\-]?\d+)?$/);
		if (!reg.test(value)) {
			return this.createError({
				path: "rate",
				message: "Invalid rate format. Please provide a valid numerical value.",
			});
		}
		return true;
	}),
	// Ensures that the startDate is not equal to or greater than endDate.
	minDate: string().test(
		"minDate",
		"Start date must not be equal the end date.",
		function (startDate) {
			const { endDate } = this.parent;
			if (!endDate || !startDate) return true;
			if (
				new Date(startDate) &&
				new Date(endDate) &&
				new Date(startDate).getTime() < new Date(endDate).getTime()
			)
				return true;
			return false;
		},
	),
	// Ensures that the endDate is not equal to or less than startDate.
	maxDate: string().test(
		"maxDate",
		"End date must not be equal the start date.",
		function (endDate) {
			const { startDate } = this.parent;
			if (!endDate || !startDate) return true;
			if (
				new Date(startDate) &&
				new Date(endDate) &&
				new Date(startDate).getTime() < new Date(endDate).getTime()
			)
				return true;
			return false;
		},
	),
	// Validates the email field using a regular expression and requires a non-empty value.
	email: string()
		.matches(
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
			"Invalid email format",
		)
		// .email("Invalid email format")
		.required("Email is required"),
	// Validates the password field:
	// Requires a non-empty value.
	// Specifies length constraints (between 8 and 16 characters).
	// Requires at least one uppercase letter, one lowercase letter, one number, and one special character.
	password: string()
		.required("Password is required")
		.min(8, "Your password length must be at least 8.")
		.max(30, "Your password length must not exceed 16.")
		.matches(
			/[A-Z]+/,
			"Your password must contain at least one uppercase letter.",
		)
		.matches(
			/[a-z]+/,
			"Your password must contain at least one lowercase letter.",
		)
		.matches(/[0-9]+/, "Your password must contain at least one number.")
		.matches(/[\!\?\*\.]+/, "Your password must contain at least one (!? *.)."),
	// A generic function that takes a type parameter:
	// Requires a non-empty value.
	// Specifies length constraints (between 1 and 200 characters).
	name: type => {
		return string()
			.required(`Please enter a ${type}`)
			.matches(
				/^(|.{1,200})$/,
				`${type} must be between 1 and 200 characters.`,
			);
	},
	// Validates the description field, requiring it to be between 5 and 2000 characters.
	description: string().matches(
		/^(|.{1,2000})$/,
		"Please provide a description between 2000 characters.",
	),
	// Validates the file field, ensuring that either imageName or file is present.
	image: mixed().test(
		"requiredImage",
		"You must upload an image file.",
		function (file) {
			if (file && !allowedTypes.includes(file.type)) {
				return this.createError({
					path: "image",
					message: "Only JPEG and PNG file types are allowed.",
				});
			}
			const maxSizeKB = 5120; // 1MB
			if (file && file.size / 1024 > maxSizeKB) {
				return this.createError({
					path: "image",
					message: `File size must be less than 5 MB.`,
				});
			}
			const { imageName } = this.parent;
			if (file && !imageName) {
				return true;
			} else if (imageName && !file) {
				return true;
			}
			return false;
		},
	),
	// Validates the imageName field, ensuring that either image or imageName is present.
	imageName: string().test(
		"requiredImageName",
		"Please provide a valid image name.",
		function (imageName) {
			const { image } = this.parent;
			if (!image && imageName) {
				return true;
			} else if (image && !imageName) {
				return true;
			}
			return false;
		},
	),
	// A generic function that takes a name parameter for number fields:
	// Requires a non-empty, valid number.
	// Specifies range constraints (between 0 and 5000).
	count: name => {
		return number()
			.typeError(`Please enter a valid number for ${name}.`)
			.required(`${name} is required. Please provide plan cost.`)
			.min(1, `${name} must be greater than or equal to 0. (Too little)`)
			.max(
				2147483647,
				`${name} must be less than or equal to 2147483647. (Very costly!)`,
			);
	},
	// Requires a non-empty value for the select field.
	select: string().required("This field is required"),
};

export const resouercesFormValidation = object().shape({
	name: validetionFunctions.name("Name"),
	description: validetionFunctions.description,
	image: validetionFunctions.image,
	imageName: validetionFunctions.imageName,
	isFragmental: boolean().notRequired(),
});
export const fragmentValidation = object().shape({
	name: validetionFunctions.name("Name"),
	image: validetionFunctions.image,
	imageName: validetionFunctions.imageName,
});
export const questFormValidation = object().shape({
	name: validetionFunctions.name("Name"),
	description: validetionFunctions.description,
	image: validetionFunctions.image,
	imageName: validetionFunctions.imageName,
});

export const badgeFormValidation = object().shape({
	name: validetionFunctions.name("Name"),
	description: validetionFunctions.description,
	image: validetionFunctions.image,
	imageName: validetionFunctions.imageName,
});

export const shopItemFormValidation = object().shape({
	startDate: validetionFunctions.minDate,
	endDate: validetionFunctions.maxDate,
	name: validetionFunctions.name("Name"),
	description: validetionFunctions.description,
	// images: validetionFunctions.images,
	// imageNames: mixed().test(
	// 	"is-valid-image-name",
	// 	"Invalid file",
	// 	function (value) {
	// 		// 			- File is required. Please upload at least one file.
	// 		// - Invalid file. Please choose a valid file.
	// 		const { images } = this.parent;
	// 		if (!images?.length && !value?.length) {
	// 			return this.createError({
	// 				path: "imageNames",
	// 				message: "Please upload at least one file",
	// 			});
	// 		}
	// 		return true;
	// 	},
	// ),
	resourceAmount: validetionFunctions.count("Amount"),
	availability: validetionFunctions.count("Availability"),
	categoryId: validetionFunctions.select,
	resourceId: validetionFunctions.select,
});

export const leaderbordFormValidation = object().shape({
	name: validetionFunctions.name("Name"),
	description: validetionFunctions.description,
	type: validetionFunctions.select,
	resourceId: validetionFunctions.select,
	startDate: validetionFunctions.minDate,
	endDate: validetionFunctions.maxDate,
});
export const singInValidation = object().shape({
	username: validetionFunctions.email,
	password: validetionFunctions.password,
});
export const singUpValidation = object().shape({
	email: validetionFunctions.email,
	password: validetionFunctions.password,
	fullName: validetionFunctions.name("Full name"),
	companyName: validetionFunctions.name("Company Name"),
});
