import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import {
	useFormAction,
	useLoaderData,
	useNavigate,
	useParams,
} from "react-router-dom";
import { useAppSubmit } from "../../../helpers/hooks";
import CustomsInput from "../../Inputs/CustomsInput";
import UplodImg from "../../Inputs/UplodImg";
import CustomSelect from "../../Inputs/CustomSelect";
import ActionButton from "../../butons/ActionButton";
import { badgeFormValidation } from "../../../helpers/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import AlertService from "../../../helpers/alertService";
import { useContext } from "react";
import { LoadingContext } from "../../Root/Root";
import ApiService from "../../../helpers/api";
import DeleteButton from "../../butons/DeleteButton";

const BadgeForm = ({ badgeSelectValue, setData, data }) => {
	const { badgesImg } = useLoaderData();
	const { itemId, guid } = useParams();
	const { setLoading } = useContext(LoadingContext);
	const navigate = useNavigate();
	const formMethods = useForm({
		defaultValues: {
			name: data?.name || "",
			description: data?.description || "",
			imageName: data?.imageName || "",
			image: "",
			prerequisiteBadgeId: data?.prerequisiteBadge?.id || null,
			// dependantBadgeIds: [],
			// deleteBadgeIds: [],
		},
		resolver: yupResolver(badgeFormValidation),
	});
	const submit = useAppSubmit(),
		action = useFormAction();
	const { handleSubmit, setValue, watch } = formMethods;
	const onSubmit = _data => {
		const formData = new FormData();
		Object.entries(_data).forEach(([key, value]) => {
			if (!value) return formData.append(key, "");
			if (Array.isArray(value)) {
				value.forEach(el => {
					formData.append(key, el);
				});
			} else {
				formData.append(key, value);
			}
		});
		if (itemId) {
			formData.append("id", itemId);
		}
		submit(formData, { method: "POST", action });
	};

	// multi select on change
	// const onChange = (_, action) => {
	// 	if (action.action === "select-option") {
	// 		const newValue = [...watch("dependantBadgeIds"), action.option.value];
	// 		setValue("dependantBadgeIds", newValue);
	// 		return;
	// 	}
	// 	if (action.action === "remove-value") {
	// 		if (
	// 			data?.dependantBadges.find(el => el.id === action.removedValue.value)
	// 		) {
	// 			const newDependantBadge = data?.dependantBadges.filter(
	// 				el => el.id !== action.removedValue.value,
	// 			);
	// 			setData({ ...data, dependantBadges: newDependantBadge });
	// 			const newDeleteBadgeIds = [
	// 				...watch("deleteBadgeIds"),
	// 				action.removedValue.value,
	// 			];
	// 			setValue("deleteBadgeIds", newDeleteBadgeIds);
	// 		}
	// 		return;
	// 	}
	// 	if (action.action === "clear") {
	// 		if (data?.dependantBadges?.length) {
	// 			const newDeleteBadgeIds = [
	// 				...watch("deleteBadgeIds"),
	// 				...data?.dependantBadges.map(el => el.id),
	// 			];
	// 			setValue("deleteBadgeIds", newDeleteBadgeIds);
	// 		}
	// 		setData({ ...data, dependantBadges: [] });
	// 		setValue("dependantBadgeIds", []);
	// 	}
	// };

	const show = id => {
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				setLoading(true);
				ApiService.delete_badge(id)
					.then(response => {
						if (response.status === 200) {
							AlertService.alert("success", "Data seved");
							navigate(`/badges/${guid}`);
							return true;
						}
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(err => {});
	};
	return (
		<FormProvider {...formMethods}>
			<Row>
				<Col sm={12}>
					<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col md={6}>
								<CustomsInput
									regName={"name"}
									type="text"
									required={true}
									label={"Badge name"}
									placeholder={"Name"}
								/>
							</Col>
							<Col md={6}>
								<CustomSelect
									cb={setValue}
									options={badgeSelectValue}
									defaultValues={data?.prerequisiteBadge?.id}
									regName={`prerequisiteBadgeId`}
									label={"Dependent badge"}
								/>
								{/* <CustomSelect
									onChange={setValue}
									// isMulti={true}
									defaultValues={data?.prerequisiteBadgeId}
									options={badgeSelectValue}
									regName={`"dependantBadgeIds"`}
									label={"Dependent badge"}
								/> */}
							</Col>
						</Row>

						<CustomsInput
							regName={"description"}
							type="textarea"
							label={"Description"}
							placeholder={"Description"}
						/>
						<UplodImg
							options={badgesImg}
							imageNameRegname={""}
							action={setValue}
							value={data?.imagePath || ""}
							labelValue="Badge Image"
							required="true"
							regName={"image"}
							regName2="imageName"
						/>
						<div className="w-100 d-flex justify-content-end gap-2">
							{itemId && (
								<DeleteButton
									type="button"
									buttonName="Delete"
									variant="outline-danger"
									cb={() => {
										show(itemId);
									}}
								/>
							)}
							<ActionButton buttonName="Save" />
						</div>
					</Form>
				</Col>
			</Row>
		</FormProvider>
	);
};
export default BadgeForm;
