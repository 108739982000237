import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import NoData from "../noData/NoData";
import { FaCheck } from "react-icons/fa";
import { TiDeleteOutline } from "react-icons/ti";
import { LoadingContext } from "../Root/Root";

function CardsBadges({ data = [], show }) {
	const [modalData, setModalData] = useState({});
	const { load, ids } = useContext(LoadingContext);

	const [open, setOpen] = useState(false);
	const handleClose = () => {
		setOpen(false);
		setModalData({});
	};
	const handleOpen = data => {
		setOpen(true);
		setModalData(data);
	};
	const navigate = useNavigate();

	return (
		<>
			<Modal
				show={open}
				onHide={handleClose}
				size="lg"
				scrollable
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header closeButton>
					<Modal.Title>{modalData?.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="mb-4 align-self-stretch gap-0">
						<h5 className="mb-2">Quests</h5>
						{modalData && modalData.quests && modalData.quests.length ? (
							modalData.quests.map((item, index) => {
								return (
									<Col md={4} key={index} className="p-2">
										<div className="d-flex p-2 flex-column justify-content-center border rounded-1">
											<div
												className="w-100 pt-3"
												style={{
													backgroundImage: `url(${item?.quest?.imagePath})`,
													backgroundPosition: "center",
													backgroundSize: "contain",
													backgroundRepeat: "no-repeat",
													height: "150px",
												}}
											/>
											<h5 className="text-center">{item?.quest?.name}</h5>
											<hr />
											<p className="d-flex justify-content-between mb-2">
												<b>Is Required</b>
												<span>
													{item?.isRequired ? (
														<FaCheck color="#24ad05" fontSize={20} />
													) : (
														<TiDeleteOutline color="#ad0505" fontSize={25} />
													)}
												</span>
											</p>
										</div>
									</Col>
								);
							})
						) : (
							<NoData height="130px" width="130px" isShowText={false} />
						)}
					</Row>
					<Row className="mb-4 align-self-stretch">
						<h5 className="mb-2">Fragments</h5>
						{modalData && modalData.fragments && modalData.fragments.length ? (
							modalData.fragments.map((item, index) => {
								return (
									<Col md={3} key={index} className="p-2">
										<div className="d-flex p-2 flex-column justify-content-center border rounded-1">
											<div
												className="w-100 pt-3"
												style={{
													backgroundImage: `url(${item?.fragment?.imagePath})`,
													backgroundPosition: "center",
													backgroundSize: "contain",
													backgroundRepeat: "no-repeat",
													height: "150px",
												}}
											/>
											<h5 className="text-center">{item?.fragment?.name}</h5>
										</div>
									</Col>
								);
							})
						) : (
							<NoData height="130px" width="130px" isShowText={false} />
						)}
					</Row>
					<Row className="mb-4 align-self-stretch">
						<h5 className="mb-2">Resources</h5>
						{modalData && modalData.resources && modalData.resources.length ? (
							modalData.resources.map((item, index) => {
								return (
									<Col md={4} key={index} className="p-2">
										<div className="d-flex p-2 flex-column justify-content-center border rounded-1">
											<div
												className="w-100 pt-3"
												style={{
													backgroundImage: `url(${item?.resource?.imagePath})`,
													backgroundPosition: "center",
													backgroundSize: "contain",
													backgroundRepeat: "no-repeat",
													height: "150px",
												}}
											/>
											<h5 className="text-center">{item?.resource?.name}</h5>
											<hr />
											<p className="d-flex justify-content-between mb-2">
												<b>Amount</b>
												<b>{item?.resourceAmount}</b>
											</p>
										</div>
									</Col>
								);
							})
						) : (
							<NoData height="130px" width="130px" isShowText={false} />
						)}
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Row className="align-self-stretch">
				{data && data?.length ? (
					data.map((item, index) => {
						return (
							<Col key={index} md={6} lg={3} className="p-2">
								<Card className="h-100 p-1">
									<div
										className="w-100 pt-2 px-2"
										// src={item.imagePath}
										style={{
											backgroundImage: `url(${item.imagePath})`,
											height: "200px",
											objectFit: "contain",
											backgroundPosition: "center",
											backgroundSize: "contain",
											backgroundRepeat: "no-repeat",
										}}
										// alt="/"
									/>
									<Card.Body className="d-flex flex-column justify-content-between">
										<Card.Title className="max-line-1" title={item.name}>
											{item.name}
										</Card.Title>
										<Card.Text title={item.description} className="max-line-3">
											{item.description}
										</Card.Text>
										<div className="">
											<hr />
											<div>
												<Button
													variant="outline-primary"
													className="w-100 mb-2"
													onClick={() => {
														const data = {
															name: item?.name,
															quests: item.badgeQuests,
															fragments: item.badgeFragments,
															resources: item.badgeResources,
														};
														handleOpen(data);
													}}>
													View
												</Button>
												<div className="d-flex gap-1">
													<Button
														variant="outline-success"
														className="w-100"
														onClick={() => {
															navigate(`form/${item.id}`);
														}}>
														Edit
													</Button>
													<Button
														variant="outline-danger"
														className="w-100"
														onClick={() => {
															if (ids.find(el => el === item.id) && load)
																return;
															show(item.id);
														}}>
														{ids.find(el => el === item.id) && load ? (
															<Spinner
																animation="border"
																role="status"
																size="sm"
																style={{ color: "#dc3545" }}
															/>
														) : (
															"Delete"
														)}
													</Button>
												</div>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						);
					})
				) : (
					<NoData />
				)}
			</Row>
		</>
	);
}

export default CardsBadges;
