import { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { FailUpLoad } from "../../helpers/helper";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useError } from "../../helpers/hooks";
import Select from "react-select";
import AlertService from "../../helpers/alertService";
import { useLoaderData } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { FaRegFolderOpen } from "react-icons/fa6";
import { FaFolder } from "react-icons/fa";
import NoData from "../noData/NoData";

const foledrNames = ["Default files", "Organization files"];
const validFileTypes = ["jpg", "png", "jpeg", "JPEG", "JPG", "PNG"];
const UplodImg = ({
	action = () => {},
	regName,
	regName2 = "",
	className = "",
	value = "",
	multiple = false,
	id = "icon-button-file",
	labelValue = "Image",
	required = false,
	isModal = true,
	isShowBootons = false,
}) => {
	const data = useLoaderData();
	const errorMessage = useError(regName);
	const errorMessage2 = useError(regName2);
	const [defaultImg, setDefaultImg] = useState(false);
	const [file, setFail] = useState("");
	const [fileName, setFilname] = useState(null);
	const [folderNmae, setFolderName] = useState("");
	useEffect(() => {
		setFail(value);
	}, [value]);
	const handleClose = () => {
		setDefaultImg(false);
		setFolderName("");
	};
	return (
		<>
			{isModal && (
				<Modal
					show={defaultImg}
					onHide={handleClose}
					size="lg"
					scrollable
					aria-labelledby="contained-modal-title-vcenter"
					centered>
					<Modal.Header closeButton>
						<div className={`${"game-folders"}`}>
							<h4
								className={`${folderNmae ? "game-folder-list" : ""}`}
								onClick={() => {
									if (!folderNmae) return;
									setFolderName("");
								}}>
								Folder
							</h4>
							<FaArrowRight />
							{folderNmae && <h4>{folderNmae}</h4>}
						</div>
					</Modal.Header>
					<Modal.Body>
						<div className="d-flex gap-3 gap-3 flex-wrap mb-3">
							{foledrNames.map(item => (
								<button
									key={item}
									type="button"
									onClick={() => {
										setFolderName(item);
									}}
									className={`btn btn-outline-secondary game-folder-button ${
										item === folderNmae ? "active_button" : ""
									}`}>
									{item === folderNmae ? (
										<FaRegFolderOpen size={25} color="#F7B62A" />
									) : (
										<FaFolder size={25} color="#F7C963" />
									)}
									<p>{item}</p>
								</button>
							))}
						</div>
						{folderNmae === "Default files" ? (
							data && data.defaultImg && data.defaultImg.length ? (
								<div className="d-flex gap-3 flex-wrap justify-content-between">
									{data.defaultImg.map(item => (
										<div
											className={`d-inline-flex  flex-column p-2 border rounded geme-modal-image ${
												fileName?.value === item.value ? "active" : ""
											}`}
											key={item.value}
											onClick={() => {
												setFilname(item);
											}}>
											<img
												className="uplod_img py-1"
												src={item.label}
												alt="file"
											/>
										</div>
									))}
								</div>
							) : (
								<NoData width={150} height={150} isShowText={false} />
							)
						) : null}
						{folderNmae === "Organization files" ? (
							data &&
							data.organiztuonFailes &&
							data.organiztuonFailes.length ? (
								<div className="d-flex gap-3 flex-wrap justify-content-between">
									{data.organiztuonFailes.map(item => (
										<div
											className={`d-inline-flex  flex-column p-2 border rounded geme-modal-image ${
												fileName?.value === item.value ? "active" : ""
											}`}
											key={item.value}
											onClick={() => {
												setFilname(item);
											}}>
											<img
												className="uplod_img py-1"
												src={item.label}
												alt="file"
											/>
										</div>
									))}
								</div>
							) : (
								<NoData width={150} height={150} isShowText={false} />
							)
						) : null}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							onClick={() => {
								setFail(fileName.label);
								action(regName2, fileName.value, {
									shouldValidate: true,
									shouldDirty: true,
								});

								action(regName, "", {
									shouldValidate: true,
									shouldDirty: true,
								});
								handleClose();
							}}>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
			<div className={`${className}`}>
				{!file && (
					<div className="form-check">
						<input
							className="form-check-input"
							type="checkbox"
							id="flexCheckDefault"
							checked={defaultImg}
							onChange={e => {
								setDefaultImg(e.target.checked);
							}}
						/>
						<label className="form-check-label" htmlFor="flexCheckDefault">
							Default images
						</label>
					</div>
				)}
				<input
					color="primary"
					type="file"
					multiple={multiple}
					onChange={async e => {
						const maxSizeKB = 5120; // 1MB
						if (e.target.files[0].size / 1024 > maxSizeKB) {
							AlertService.alert(
								"warning",
								`This ${e.target.files[0].name} size must be less than 5 MB.`,
							);
							return;
						}
						const fileName = await new FailUpLoad().readFile(
							e.target.files[0],
							validFileTypes,
						);

						setFail(fileName);
						action(regName2, "", {
							shouldValidate: true,
							shouldDirty: true,
						});

						action(regName, e.target.files[0], {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					id={id}
					accept=".jpg,.png,jpeg,.JPEG,.JPG,.PNG,"
					style={{ display: "none" }}
				/>
				{labelValue ? (
					<label className="mb-2 form-label">
						{labelValue}
						{required ? <span className="required ms-1">*</span> : ""}
					</label>
				) : null}
				<br />
				{!file && !defaultImg && (
					<>
						<Button
							variant="primary"
							style={{ cursor: "pointer !important" }}
							className={`d-flex align-items-center px-3 ${
								!!errorMessage || !!errorMessage2 ? "button_error" : ""
							}`}
							onClick={() => {
								if (!id) return false;
								document.getElementById(id)?.click();
							}}>
							<AiOutlineDownload size={21} className="me-2" />
							<span>Upload</span>
						</Button>
					</>
				)}
				{!file && defaultImg && isShowBootons && (
					<Row>
						<Col md={12}>
							<div className="d-flex gap-3 gap-3 flex-wrap mb-3">
								{foledrNames.map(item => (
									<button
										key={item}
										type="button"
										onClick={() => {
											setFolderName(item);
										}}
										className={`btn btn-outline-secondary game-folder-button ${
											item === folderNmae ? "active_button" : ""
										}`}>
										{item === folderNmae ? (
											<FaRegFolderOpen size={25} color="#F7B62A" />
										) : (
											<FaFolder size={25} color="#F7C963" />
										)}
										<p>{item}</p>
									</button>
								))}
							</div>
							{folderNmae === "Default files" ? (
								data && data.fragmentImges && data.fragmentImges.length ? (
									<div className="d-flex gap-3 flex-wrap justify-content-between">
										{data.fragmentImges.map(item => (
											<div
												className={`d-inline-flex  flex-column p-2 border rounded geme-modal-image ${
													fileName?.value === item.value ? "active" : ""
												}`}
												key={item.value}
												onClick={() => {
													setFilname(item);
													setFail(item.label);
													action(regName2, item.value, {
														shouldValidate: true,
														shouldDirty: true,
													});

													action(regName, "", {
														shouldValidate: true,
														shouldDirty: true,
													});
												}}>
												<img
													className="uplod_img py-1"
													src={item.label}
													alt="file"
												/>
											</div>
										))}
									</div>
								) : (
									<NoData width={150} height={150} isShowText={false} />
								)
							) : null}
							{folderNmae === "Organization files" ? (
								data &&
								data.fragmentOrganiztuonFailes &&
								data.fragmentOrganiztuonFailes.length ? (
									<div className="d-flex gap-3 flex-wrap justify-content-between">
										{data.fragmentOrganiztuonFailes.map(item => (
											<div
												className={`d-inline-flex  flex-column p-2 border rounded geme-modal-image ${
													fileName?.value === item.value ? "active" : ""
												}`}
												key={item.value}
												onClick={() => {
													setFilname(item);
													setFail(item.label);
													action(regName2, item.value, {
														shouldValidate: true,
														shouldDirty: true,
													});

													action(regName, "", {
														shouldValidate: true,
														shouldDirty: true,
													});
												}}>
												<img
													className="uplod_img py-1"
													src={item.label}
													alt="file"
												/>
											</div>
										))}
									</div>
								) : (
									<NoData width={150} height={150} isShowText={false} />
								)
							) : null}
						</Col>
					</Row>
				)}
				{!!file && (
					<div className="position-relative d-inline-flex  flex-column p-2 border rounded">
						<img
							className="uplod_img py-1"
							src={file}
							srcSet={file}
							alt="file"
						/>
						<Button
							size="sm"
							onClick={() => {
								setFail("");
								if (isModal) {
									setDefaultImg(false);
								}
								action(regName2, "", {
									shouldValidate: true,
									shouldDirty: true,
								});
								action(regName, "", {
									shouldValidate: true,
									shouldDirty: true,
								});
							}}
							variant="outline-danger">
							Remove
						</Button>
					</div>
				)}
			</div>

			{(!!errorMessage || errorMessage2) && !file && (
				<span className="helper_text">{errorMessage || errorMessage2}</span>
			)}
		</>
	);
};

export default UplodImg;
