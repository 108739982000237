import React, { useState, useEffect, useContext } from "react";
import { TimerContext } from "../../App";
import { useLocation } from "react-router-dom";

const Timer = () => {
	const [seconds, setSeconds] = useState(undefined);
	const { isUpdateTimer, setIsUpdateTimer } = useContext(TimerContext);
	const { pathname } = useLocation();
	useEffect(() => {
		if (isUpdateTimer) {
			setSeconds(300);
			setIsUpdateTimer(false);
		}
	}, [isUpdateTimer, setIsUpdateTimer]);
	useEffect(() => {
		let timerInterval;
		if (typeof seconds !== "undefined") {
			timerInterval = setInterval(() => {
				setSeconds(prevSeconds => (prevSeconds > 0 ? prevSeconds - 1 : 0));
			}, 1000);
			if (seconds === 0) {
				window.close();
			}
		}
		return () => clearInterval(timerInterval);
	}, [seconds]);

	const formatTime = timeInSeconds => {
		const minutes = Math.floor(timeInSeconds / 60);
		const remainingSeconds = timeInSeconds % 60;
		return `${
			String(minutes).padStart(2, "0") ? String(minutes).padStart(2, "0") : "00"
		}:${
			String(remainingSeconds).padStart(2, "0")
				? String(remainingSeconds).padStart(2, "0")
				: "00"
		}`;
	};
	if (
		pathname === "/" ||
		pathname === "/sing-up" ||
		pathname === "/forgot-password" ||
		pathname.includes("/reset-password")
	) {
		return null;
	}
	if (!seconds) {
		<span
			className=""
			style={{
				fontWeight: 700,
			}}>
			Session expired
		</span>;
	}
	return (
		// <Draggable bounds="parent">
		<div className="">
			<span
				className="mx-1"
				style={{
					fontWeight: 600,
					fontSize: "13px",
				}}>
				Session will expired
			</span>
			<span
				className=""
				style={{
					fontWeight: 700,
					color: seconds < 10 ? "red" : "#fff",
				}}>
				{seconds ? formatTime(seconds) : "00:00"}
			</span>
		</div>
		// </Draggable>
	);
};

export default Timer;
