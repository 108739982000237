import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
	useFormAction,
	useLoaderData,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { Fragment, useContext, useEffect, useMemo } from "react";
import { useAppSubmit, useQuery } from "../../../helpers/hooks";
import CustomsInput from "../../Inputs/CustomsInput";
import UplodImg from "../../Inputs/UplodImg";
import ActionButton from "../../butons/ActionButton";
import { resouercesFormValidation } from "../../../helpers/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import AlertService from "../../../helpers/alertService";
import ApiService from "../../../helpers/api";
import { LoadingContext } from "../../Root/Root";
import DeleteButton from "../../butons/DeleteButton";

const ResouercesForm = () => {
	const { lodardata, resouercesImg } = useLoaderData();
	const { state } = useLocation();
	let query = useQuery();
	const isFirstResouerces = query.get("isDefault");
	const navigate = useNavigate();
	const { setLoading } = useContext(LoadingContext);
	const { itemId, guid } = useParams();
	const actiondata = {};
	const formMethods = useForm({
		defaultValues: {
			name: lodardata?.data?.name || actiondata?.name || "",
			description:
				lodardata?.data?.description || actiondata?.description || "",
			imageName: lodardata?.data?.imageName || actiondata?.imageName || "",
			image: "",
			rate: lodardata?.data?.rate
				? lodardata?.data?.rate
				: actiondata?.rate
				? actiondata?.rate
				: state?.isDefault ||
				  lodardata?.data?.isDefault ||
				  isFirstResouerces === "true"
				? "1"
				: "",
			isFragmental:
				lodardata?.data?.isFragmental || actiondata?.isFragmental || false,
		},
		resolver: yupResolver(resouercesFormValidation),
	});
	const submit = useAppSubmit();
	const action = useFormAction();
	const { handleSubmit, setValue, control, register } = formMethods;

	const isFragmental = useWatch({ control, name: "isFragmental" });

	const isDisabledRate = useMemo(() => {
		return (
			state?.isDefault ||
			lodardata?.data?.isDefault ||
			isFirstResouerces === "true"
		);
	}, [state, lodardata, isFirstResouerces]);

	const onSubmit = async _data => {
		try {
			const formData = new FormData();
			Object.entries(_data).forEach(([key, value]) => {
				// if (!value) {
				// 	return formData.append(key, "");
				// }
				formData.append(key, value);
			});
			if (itemId) {
				formData.append("id", itemId);
			}
			submit(formData, { method: "POST", action });
		} catch (error) {
			console.error(error);
		}
	};
	const show = id => {
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				setLoading(true);
				ApiService.deleteResource(id)
					.then(response => {
						if (response.status === 200) {
							navigate(`/resources/${guid}`);
							AlertService.alert("success", "Data seved");
							return true;
						}
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(err => {});
	};
	return (
		<FormProvider {...formMethods}>
			<Row>
				<Col sm={12}>
					<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col md={6}>
								<CustomsInput
									regName={"name"}
									type="text"
									label={"Resource name"}
									placeholder={"Name"}
								/>
							</Col>
							<Col md={6} className="">
								{(!lodardata?.data?.isDefault ||
									!(isFirstResouerces === "true")) && (
									<CustomsInput
										regName={"rate"}
										type="number"
										label={"Rate"}
										placeholder={"Rate"}
										disabled={isDisabledRate}
									/>
								)}
							</Col>
						</Row>
						{!itemId &&
							!state?.isDefault &&
							!(isFirstResouerces === "true") && (
								<Fragment>
									<Form.Label>Is fragmentary</Form.Label>
									<Form.Check
										id="isFragmentary"
										className="mb-3 align-items-center"
										label="Yes"
										{...register("isFragmental")}
										type={"checkbox"}
									/>
								</Fragment>
							)}

						<CustomsInput
							regName={"description"}
							type="textarea"
							label={"Description"}
							placeholder={"Description"}
						/>
						<UplodImg
							options={resouercesImg}
							action={setValue}
							value={lodardata?.data?.imagePath || ""}
							required={true}
							labelValue="Resource Image"
							regName={"image"}
							regName2="imageName"
						/>
						<div className="w-100 d-flex justify-content-end gap-2">
							{itemId && (
								<DeleteButton
									type="button"
									buttonName="Delete"
									variant="outline-danger"
									cb={() => {
										show(itemId);
									}}
								/>
							)}
							<ActionButton buttonName="Save" />
						</div>
					</Form>
				</Col>
			</Row>
		</FormProvider>
	);
};
export default ResouercesForm;
