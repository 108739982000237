export class FailUpLoad {
	static validFileTypes = ["jpg", "png", "jpeg", "JPEG", "JPG", "PNG"];
	async readFile(file, validFileTypes = null, progressCallback) {
		const CHUNK_SIZE = 1024 * 1024 * 10000; // 10000 MB -> 10GB
		let offset = 0;
		const fileSize = file.size;
		const chunks = [];

		// Validate file extension
		const fileName = file.name;
		const lastDotIndex = fileName.lastIndexOf(".");
		const fileExtention =
			lastDotIndex !== -1
				? fileName.substring(lastDotIndex + 1).toLowerCase()
				: "";
		const isValid = validFileTypes.includes(fileExtention);
		if (!isValid) {
			throw new Error("Invalid file extension");
		}

		while (offset < fileSize) {
			const end = Math.min(offset + CHUNK_SIZE, fileSize);
			const chunk = await this.readChunk(file, offset, end);
			chunks.push(chunk);
			offset = end;
		}

		const videoBlob = new Blob(chunks, { type: file.type });
		const videoUrl = URL.createObjectURL(videoBlob);
		return videoUrl;
	}

	async readChunk(file, start, end, cb) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			const blob = file.slice(start, end);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error); // Pass the error to reject()
			reader.readAsArrayBuffer(blob);
			if (cb) {
				reader.onprogress = function (data) {
					if (data.lengthComputable) {
						var progress = parseInt((data.loaded / data.total) * 100, 10);
						cb(progress);
					}
				};
			}
		});
	}
}

export const toFormData = arg => {
	const formData = new FormData();
	Object.entries(arg).forEach(([key, value]) => {
		if (Array.isArray(value) || typeof value === "boolean") {
			formData.append(key, JSON.stringify(value));
		}
		if (value instanceof File) {
			formData.append(key, value, value.name);
		}
		if (typeof value === "string") {
			formData.append(key, value);
		}
		if (value === undefined) {
			formData.append(key, "");
		}
	});
	return formData;
};

export const toObject = arg => {
	const newObj = Object.fromEntries(arg);

	Object.entries(newObj).forEach(([key, value]) => {
		if (typeof value === "string") {
			if (
				(value.includes("[{") && Array.isArray(JSON.parse(value))) ||
				value === "true" ||
				value === "false"
			) {
				newObj[key] = JSON.parse(value);
			} else {
				newObj[key] = value;
			}
		}
		if (value === undefined) {
			newObj[key] = "";
		}
	});

	return newObj;
};

export const handleChange = (regName, value, cb) => {
	cb(regName, value, {
		shouldValidate: true,
		shouldDirty: true,
	});
};
