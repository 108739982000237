import { FormProvider, useForm } from "react-hook-form";
import CustomSelect from "../../components/Inputs/CustomSelect";
import { Col, Form, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { leaderbordFormValidation } from "../../helpers/validation";
import CustomsInput from "../../components/Inputs/CustomsInput";
import { useAppSubmit } from "../../helpers/hooks";
import {
	useActionData,
	useFormAction,
	useLoaderData,
	useNavigate,
	useParams,
} from "react-router-dom";
import { useContext, useEffect } from "react";
import ApiService from "../../helpers/api";
import FormLayout from "../../components/layout/FormLayout";
import ActionButton from "../../components/butons/ActionButton";
import AlertService from "../../helpers/alertService";
import { toObject } from "../../helpers/helper";
import moment from "moment";
import { TimerContext } from "../../App";
import { LoadingContext } from "../../components/Root/Root";
import DeleteButton from "../../components/butons/DeleteButton";

const optionsValue = [
	{ value: 0, label: "Individual" },
	{ value: 1, label: "Group" },
	// { value: 2, label: "Cluster" },
];
const getType = type => {
	switch (type) {
		case 0:
			return "Individual";
		case 1:
			return "Group";
		case 2:
			return "Cluster";
		default:
			return "";
	}
};

const Component = () => {
	const { data, resourceValue } = useLoaderData();
	const { setLoading } = useContext(LoadingContext);
	const submit = useAppSubmit(),
		action = useFormAction();
	const actiondata = useActionData();
	const navigate = useNavigate();
	const { itemId, guid } = useParams();
	const formMethods = useForm({
		defaultValues: {
			type: data?.data?.type.toString() || "",
			description: data?.data?.description || "",
			name: data?.data?.name || "",
			resourceId: data?.data?.resourceId || "",
			startDate: data?.data?.startDate
				? moment(new Date(data?.data?.startDate)).format("YYYY-MM-DD")
				: "",
			endDate: data?.data?.endDate
				? moment(new Date(data?.data?.endDate)).format("YYYY-MM-DD")
				: "",
		},
		resolver: yupResolver(leaderbordFormValidation),
	});

	const { handleSubmit, setValue } = formMethods;
	const { setIsUpdateTimer } = useContext(TimerContext);

	useEffect(() => {
		if (resourceValue) {
			setIsUpdateTimer(true);
		}
	}, [resourceValue, setIsUpdateTimer]);
	useEffect(() => {
		if (actiondata?.data) {
			navigate(`/leaderboard/${guid}`);
		} else if (actiondata === "update") {
			navigate(`/leaderboard/${guid}`);
		}
	}, [actiondata, guid, navigate]);

	const onSubmit = _data => {
		const formData = new FormData();
		Object.entries(_data).forEach(([key, value]) => {
			if (value) formData.append(key, value);
		});
		if (itemId) {
			formData.append("id", itemId);
		}
		submit(formData, { method: "POST", action });
	};
	const show = id => {
		AlertService.alertConfirm(`Are you sure ?`)
			.then(res => {
				setLoading(true);
				ApiService.delete_leaderboard(id)
					.then(response => {
						if (response.status === 200) {
							AlertService.alert("success", "Data seved");
							navigate(`/leaderboard/${guid}`);
							return true;
						}
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(err => {});
	};

	return (
		<FormLayout pageTitle="Leaderboard Form" backTo={"leaderboard"}>
			<Row>
				<Col sm={12}>
					<FormProvider {...formMethods}>
						<Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
							<Row>
								<Col md={6}>
									<CustomsInput
										regName={"name"}
										type="text"
										required={true}
										label={"Leaderbord name"}
										placeholder={"Name"}
									/>
								</Col>
							</Row>

							<CustomsInput
								regName={"description"}
								type="textarea"
								label={"Description"}
								placeholder={"Description"}
							/>
							<Row>
								<Col md={6}>
									<CustomSelect
										required={true}
										cb={setValue}
										disabled={!!getType(data?.data?.type)}
										defaultValues={getType(data?.data?.type) || ""}
										options={optionsValue}
										regName={`type`}
										label={"Select the leder type"}
									/>
								</Col>
								<Col md={6}>
									<CustomSelect
										required={true}
										cb={setValue}
										disabled={!!data?.data?.resourceId}
										defaultValues={data?.data?.resourceId || ""}
										regName={`resourceId`}
										label={"Resource"}
										options={resourceValue}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<CustomsInput
										maiDate={true}
										regName={"startDate"}
										type="date"
										label={"Start date"}
										placeholder={"Category name"}
									/>
								</Col>
								<Col md={6}>
									<CustomsInput
										minDate={true}
										regName={"endDate"}
										type="date"
										label={"End date"}
										placeholder={"Category name"}
									/>
								</Col>
							</Row>
							<div className="w-100 d-flex justify-content-end gap-2">
								{itemId && (
									<DeleteButton
										type="button"
										buttonName="Delete"
										variant="outline-danger"
										cb={() => {
											show(itemId);
										}}
									/>
								)}
								<ActionButton buttonName="Save" />
							</div>
						</Form>
					</FormProvider>
				</Col>
			</Row>
		</FormLayout>
	);
};
const action = async ({ request, params: { itemId } }) => {
	try {
		const requestData = await request.formData();
		const obj = toObject(requestData);
		obj.type = Number(obj.type);

		if (itemId) {
			const data = await ApiService.update_leaderboard(obj);
			if (data.data) {
				AlertService.alert("success", "Data seved");
				return "update";
			}
			return null;
		}
		const data = await ApiService.create_leaderboard(obj);
		if (data.status === 200) AlertService.alert("success", "Data seved");
		return data;
	} catch (error) {
		throw new Response("Not Found", { status: 404 });
	}
};
const loader = async ({ request, params: { itemId } }) => {
	try {
		const resourceValue = [];
		const resource = await ApiService.getAllresource();
		if (!resource.data) {
			return null;
		}
		resource.data.forEach(el => {
			if (!el.isFragmental) {
				resourceValue.push({
					value: el.id,
					label: el.name,
				});
			}
		});
		let data;
		if (itemId) {
			data = await ApiService.get_leaderboard_by_id(itemId);
		}
		return { data, resourceValue };
	} catch (error) {
		throw new Response("Not Found", { status: 404 });
	}
};
const LeaderbordForm = Object.assign(Component, { loader, action });
export default LeaderbordForm;
