import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { FaCheck } from "react-icons/fa";
import { TiDeleteOutline } from "react-icons/ti";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import NoData from "../noData/NoData";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { LoadingContext } from "../Root/Root";

function CardsShop({ data = [], show }) {
	const navigate = useNavigate();
	const { load, ids } = useContext(LoadingContext);
	return (
		<>
			<Row className="align-self-stretch">
				{data && data.length ? (
					data.map((item, index) => {
						return (
							<Col key={index} md={4} className="p-2">
								<Card className="h-100 p-1">
									<Swiper
										cssMode={true}
										navigation={true}
										loop={true}
										height={"200px"}
										pagination={{
											clickable: true,
										}}
										mousewheel={true}
										keyboard={true}
										modules={[Navigation, Pagination, Mousewheel, Keyboard]}
										className="mySwiper">
										{Array.isArray(item.imagePaths) &&
											item.imagePaths.length &&
											item.imagePaths.map((image, i) => (
												<SwiperSlide key={image} className="">
													{/* <img src={image} /> */}
													<div
														className="w-100 pt-2"
														// src={image}
														style={{
															backgroundImage: `url(${image})`,
															height: "200px",
															objectFit: "contain",
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
														}}
													/>
												</SwiperSlide>
											))}
									</Swiper>
									<Card.Body className="d-flex flex-column justify-content-between pt-0">
										<div>
											<h4 className="text-center max-line-3">{item.name}</h4>
											<hr />
										</div>
										<div>
											<p className="max-line-3" title={item?.description}>
												{item?.description}
											</p>
											<div className="d-flex justify-content-between align-items-center mb-2">
												<b className="">Price</b>
												<div className="d-flex gap-2 d-flex align-items-center">
													<div
														title={item?.resource.name}
														className="pt-2"
														style={{
															backgroundImage: `url(${item?.resource.imagePath})`,
															height: "40px",
															width: "40px",
															objectFit: "contain",
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
														}}
													/>
													<b className="d-block">{item.resourceAmount}</b>
												</div>
											</div>

											<p className="d-flex justify-content-between">
												<b>Availability </b>
												<b>{item.availability}</b>
											</p>
											{item.startDate ? (
												<p className="d-flex justify-content-between">
													<b>Start date </b>
													<b>{moment(item?.startDate).format("ll")}</b>
												</p>
											) : null}
											{item.endDate ? (
												<p className="d-flex justify-content-between">
													<b>End date </b>
													<b>{moment(item?.endDate).format("ll")}</b>
												</p>
											) : null}
											<p className="d-flex justify-content-between">
												<b>Enabled </b>
												<b>
													{item.enabled ? (
														<FaCheck color="#24ad05" fontSize={20} />
													) : (
														<TiDeleteOutline color="#ad0505" fontSize={25} />
													)}
												</b>
											</p>
										</div>
										<div className="mt-2">
											<div className="d-flex gap-1">
												<Button
													variant="outline-success"
													className="w-100"
													onClick={() => {
														navigate(`form/${item.id}`);
													}}>
													Edit
												</Button>
												<Button
													variant="outline-danger"
													className="w-100"
													onClick={() => {
														if (ids.find(el => el === item.id) && load) return;
														show(item.id);
													}}>
													{ids.find(el => el === item.id) && load ? (
														<Spinner
															animation="border"
															role="status"
															size="sm"
															style={{ color: "#dc3545" }}
														/>
													) : (
														"Delete"
													)}
												</Button>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						);
					})
				) : (
					<NoData />
				)}
			</Row>
		</>
	);
}

export default CardsShop;
