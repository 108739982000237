import axios from "axios";
import AlertService from "./alertService";
// export const baseURL = "https://gamifyup.webinarsystems.ru";
// export const baseURL = "https://ardy.mindalay.net/gamify/";
export const baseURL = "https://api.gamifyup.com/";

// const identificationURL = "https://c02d-87-241-138-217.ngrok-free.app/";

export const instance = axios.create({
	baseURL: baseURL,
});
const api = axios.create({
	baseURL: baseURL,
	withCredentials: true,
	headers: {
		// "Content-Type": "application/json",
		"ngrok-skip-browser-warning": "true",
	},
});
api.interceptors.request.use(async config => {
	config.headers = {
		Authorization: `${
			localStorage.getItem("token") ? localStorage.getItem("token") : ""
		}`,
		PublicKey: `${
			localStorage.getItem("publicKey") ? localStorage.getItem("publicKey") : ""
		}`,
		AdminToken: !!window.location.pathname.split("/")[2]
			? window.location.pathname.split("/")[2]
			: "",
	};
	return config;
});

api.interceptors.response.use(
	response => {
		if (
			(response && response.data && response.data?.status === 500) ||
			response.data.respcode
		) {
			if (response.data.message) {
				AlertService.alert("error", response.data.message);
			} else {
				AlertService.alert("error", "Sorry, something went wrong!");
			}
		} else if (response && response.data && response.data?.status === 404) {
			// window.close();
		}
		if (response && response.data) {
			return !response.data.respcode
				? response.data
				: new Error("Bad response from server");
		}
	},
	error => {
		if (error.response && error.response.status === 401) {
			window.close();
			AlertService.alert("error", "401 unauthorized");
		}
		if (error.response && error.response.status === 500) {
			AlertService.alert("error", "Sorry, something went wrong!");
		}
		if (error.response) {
			const errors = error.response.data.errors;
			return Promise.reject(errors || error.response.data.ExcMessage || error);
		}
	},
);

class ApiService {
	static create_resource(data) {
		return api.post(`/resource/create`, data);
	}
	static getAllresource() {
		return api.get("/resource/getAll");
	}
	static deleteResource(id) {
		return api.delete(`/resource/delete?id=${id}`);
	}
	static getResource(id) {
		return api.get(`/resource/getResource?id=${id}`);
	}
	static updateResource(data) {
		return api.put(`/resource/update`, data);
	}
	static get_all_fragments() {
		return api.get("/fragment/getAllFragments");
	}
	static create_resource_fragment(data) {
		return api.post(`fragment/create`, data);
	}
	static update_resource_fragment(data) {
		return api.put(`fragment/update`, data);
	}
	static delete_resource_fragment(id) {
		return api.delete(`fragment/delete?id=${id}`);
	}

	// 	fragment/create -- > model (fromform)-->post
	// fragment/update-- > model (fromform)-->post

	static create_shopItem(data) {
		return api.post(`/shopItem/create`, data);
	}
	static deleteShopItem(id) {
		return api.delete(`/shopItem/delete?id=${id}`);
	}
	static getShopItem(id) {
		return api.get(`/shopItem/get?id=${id}`);
	}
	static updateShopItem(data) {
		return api.put(`/shopItem/update`, data);
	}
	static deleteFragment(id) {
		return api.delete(`/resource/deleteFragment?id=${id}`);
	}
	static get_badge(id) {
		return api.get(`/badge/getById?id=${id}`);
	}
	static get_all_badge(id) {
		if (id) {
			return api.get(`/badge/getAll?badgeId=${id}`);
		}
		return api.get(`/badge/getAll`);
	}
	static delete_badge(id) {
		return api.delete(`/badge/delete?id=${id}`);
	}
	static craeate_badge(data) {
		return api.post(`/badge/create`, data);
	}
	static update_badge(data) {
		return api.put("/badge/update", data);
	}
	static create_badge_quest(data) {
		return api.post("/badgeQuest/create", data);
	}
	static update_badge_quest(data) {
		return api.put("/badgeQuest/update", data);
	}
	static delete_badge_quest(id) {
		return api.delete(`/badgeQuest/delete?id=${id}`);
	}
	static create_badge_resource(data) {
		return api.post("/badgeResource/create", data);
	}
	static update_badge_resource(data) {
		return api.put("/badgeResource/update", data);
	}
	static delete_badge_resource(id) {
		return api.delete(`/badgeResource/delete?id=${id}`);
	}
	static create_badge_fragment(data) {
		return api.post("/badgeFragment/create", data);
	}
	static update_badge_fragment(data) {
		return api.put("/badgeFragment/Update", data);
	}
	static delete_badge_fragment(id) {
		return api.delete(`/badgeFragment/delete?id=${id}`);
	}
	static getByEntity() {
		return api.get(`/badge/getByEntity`);
	}
	static get_all_quest() {
		return api.get(`/quest/getAllQuests`);
	}
	static create_quest(data) {
		return api.post(`/quest/create`, data);
	}
	static update_quest(data) {
		return api.put(`/quest/update`, data);
	}
	static delete_quest(id) {
		return api.delete(`/quest/delete?id=${id}`);
	}
	static get_quest() {
		return api.get(`/quest/getQuest`);
	}
	static create_quest_resource(data) {
		return api.post(`/questResource/create`, data);
	}
	static update_quest_resource(data) {
		return api.put(`/questResource/update`, data);
	}
	static delete_qust_resource(id) {
		return api.delete(`/questResource/delete?id=${id}`);
	}
	static create_quest_fragment(data) {
		return api.post(`/questFragment/create`, data);
	}
	static update_quest_fragment(data) {
		return api.put(`/questFragment/update`, data);
	}
	static delete_qust_fragment(id) {
		return api.delete(`/questFragment/delete?id=${id}`);
	}
	static create_category(data) {
		return api.post(`/category/create`, data);
	}
	static update_category(data) {
		return api.put(`/category/update`, data);
	}
	static get_categories(id) {
		if (id) {
			return api.get(`/category/getCategories?parentId=${id}`);
		}
		return api.get(`/category/getCategories`);
	}
	static get_category_id(id) {
		return api.get(`category/getShopItemsByCategoryId?id=${id}`);
	}
	static delete_category(id) {
		return api.delete(`category/delete?id=${id}`);
	}
	static get_leaderboard() {
		return api.get(`leaderboard/getAllByToken`);
	}
	static create_leaderboard(data) {
		return api.post(`leaderboard/create`, data);
	}
	static update_leaderboard(data) {
		return api.put(`leaderboard/update`, data);
	}
	static get_leaderboard_by_id(id) {
		return api.get(`leaderboard/getForAdmin?id=${id}`);
	}
	static delete_leaderboard(id) {
		return api.delete(`leaderboard/delete?id=${id}`);
	}
	static entity_type_create(data) {
		return api.post("entityType/create", data);
	}
	static entity_type_update(data) {
		return api.put("entityType/update", data);
	}
	static entity_type_getAll() {
		return api.get("entityType/getAll");
	}
	static entity_type_deletel(id) {
		return api.delete(`entityType/delete?id=${id}`);
	}
	static partner_create(data) {
		return api.post(`partner/create`, data);
	}
	static partner_loginIdentity(data) {
		return api.post("partner/loginIdentity", data);
	}
	static identity_getPWDAccessToken() {
		return api.get("partner/getCurrent");
	}
	static getStaicFailes(path) {
		return api.get(`file/getDefaultFile?path=${path}`);
	}
	static getStaicOrganiztuonFailes(path) {
		return api.get(`file/getOrganizationFile?path=${path}`);
	}
	static getFiles(paths = []) {
		return api.post(`file/getFiles`, paths);
	}
	//-----identificationURL-----
	static entityTypeCreate(data) {
		return api.post(`entityType/create`, data);
	}
	static entityTypeCreate(data) {
		return api.put(`entityType/Update`, data);
	}
	static entityTypeGetAll() {
		return api.post(`entityType/getAll`);
	}
	static entityTypeCreate(id) {
		return api.delete(`entityType/Delete?id=${id}`);
	}
}
export default ApiService;
