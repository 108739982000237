import ApiService from "../../helpers/api";
import {
	useActionData,
	useLoaderData,
	useNavigate,
	useParams,
} from "react-router-dom";
import ResouercesForm from "../../components/forms/resouerce/ResouercesForm";
import { useContext, useEffect, useState } from "react";
import FragmentsForm from "../../components/forms/resouerce/FragmentsForm";
import FormLayout from "../../components/layout/FormLayout";
import AlertService from "../../helpers/alertService";
import { TimerContext } from "../../App";
import { Modal } from "react-bootstrap";
import { Star } from "../../assets/icons";
// form/${actiondata?.data.id}
const Component = () => {
	const actiondata = useActionData();
	const { lodardata, resouercesImg, fragmentImg } = useLoaderData();
	const [data, setData] = useState(lodardata?.data);
	const navigate = useNavigate();
	const { guid, itemId } = useParams();
	const { setIsUpdateTimer } = useContext(TimerContext);
	const [show, setShow] = useState(false);
	const [isAnimation, setIsAnimation] = useState(true);
	const handleClose = () => {
		setShow(false);
		navigate(`/resources/${guid}`);
	};

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setIsAnimation(false);
			}, 3000);
		}
	}, [show]);

	useEffect(() => {
		setIsUpdateTimer(true);
	}, [lodardata, resouercesImg, fragmentImg, setIsUpdateTimer]);
	useEffect(() => {
		if (lodardata && lodardata.data) setData(lodardata?.data);
	}, [lodardata]);
	useEffect(() => {
		if (actiondata && actiondata.data && actiondata?.data?.isDefault) {
			setShow(true);
		}
		if (actiondata && actiondata.data && actiondata.data.rate) {
			navigate(`/resources/${guid}`);
		} else if (actiondata && actiondata.data) {
			navigate(`/resources/${guid}/form/${actiondata.data.id}`);
		} else if (actiondata === "update") {
			navigate(`/resources/${guid}`);
		}
	}, [actiondata, guid, navigate, itemId]);
	return (
		<FormLayout pageTitle="Resource Form" backTo={"resources"}>
			<Modal
				show={show}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				className="congratulations_modal"
				centered>
				<Modal.Header
					closeButton
					style={{
						borderBottom: "none",
					}}>
					<div
						className="congratulations_modal_star"
						style={{
							backgroundColor: "#fff",
						}}>
						<Star
							className={`${isAnimation ? "scale-up-center" : ""}`}
							width={100}
							height={100}
						/>
					</div>
				</Modal.Header>
				<Modal.Body>
					<h2 className="congratulations_modal_title">Congratulation</h2>
					<span style={{ textAlign: "center" }}>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
						mollitia, molestiae quas vel sint commodi repudiandae consequuntur
						voluptatum laborum numquam blanditiis harum quisquam eius sed odit
						fugiat iusto fuga praesentium optio, eaque rerum! Provident
						similique accusantium nemo autem. Veritatis obcaecati tenetur iure
						eius earum ut molestias architecto voluptate aliquam nihil, eveniet
						aliquid culpa officia aut! Impedit sit sunt quaerat,
					</span>
				</Modal.Body>
			</Modal>
			<ResouercesForm />
			{data && data.fragments && (
				<FragmentsForm data={data} setData={setData} />
			)}
		</FormLayout>
	);
};

const action = async ({ request, params: { itemId } }) => {
	try {
		const requestData = await request.formData();
		if (itemId) {
			const data = await ApiService.updateResource(requestData);
			if (data.status === 200) {
				AlertService.alert("success", "Data seved");
				return "update";
			}
			return null;
		}
		const data = await ApiService.create_resource(requestData);
		// redirect(`${data.id}`);
		return data;
	} catch (error) {
		return null;
	}
};
const loader = async ({ request, params: { itemId } }) => {
	try {
		const paths = ["Resource", "Fragment"];
		const defaultFiles = await ApiService.getFiles(["Resource", "Fragment"]);
		let organiztuonFailes = [],
			defaultImg = [],
			fragmentOrganiztuonFailes = [],
			fragmentImges = [];
		if (defaultFiles && defaultFiles.data) {
			Object.entries(defaultFiles.data).forEach(([key, value]) => {
				switch (key) {
					case "DEFAULT/Fragment":
						fragmentOrganiztuonFailes = Object.entries(value).map(
							([key, value]) => ({
								value: key,
								label: value,
							}),
						);
						return;
					case "Fragment":
						fragmentImges = Object.entries(value).map(([key, value]) => ({
							value: key,
							label: value,
						}));
						return;
					case "DEFAULT/Resource":
						defaultImg = Object.entries(value).map(([key, value]) => ({
							value: key,
							label: value,
						}));
						return;

					case "Resource":
						organiztuonFailes = Object.entries(value).map(([key, value]) => ({
							value: key,
							label: value,
						}));
						return;

					default:
						return;
				}
			});
		}
		if (itemId) {
			const lodardata = await ApiService.getResource(itemId);
			return {
				lodardata,
				defaultImg,
				fragmentImges,
				organiztuonFailes,
				fragmentOrganiztuonFailes,
			};
		}
		return {
			defaultImg,
			fragmentImges,
			organiztuonFailes,
			fragmentOrganiztuonFailes,
		};
	} catch (error) {
		return error;
	}
};
const ResouercesForms = Object.assign(Component, { action, loader });

export default ResouercesForms;
